import React from "react";
import {
  BrowserRouter as BrowserRouterBase,
  HashRouter as HashRouterBase,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import App from "./App";
import { AppProvider } from "../context";
import Login from "./Login";
import { getJWT } from "../utils";
import Pricing from "./Pricing";
import Account from "./Account";

function PrivateRoute({ component: Component, ...rest }) {
  const isLoggedIn = getJWT();

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: props.location.pathname },
            }}
          />
        )
      }
    />
  );
}

function AppRouter() {
  const SelectedRouter = !window.cordova ? BrowserRouterBase : HashRouterBase;

  return (
    <AppProvider>
      <SelectedRouter>
        <Switch>
          <Route path="/sign-in" component={Login} />
          <Route path="/pricing" render={() => <App focus="pricing" />} />
          <Route path="/get-started" render={() => <App focus="features" />} />
          <PrivateRoute path="/account" component={Account} />
          <PrivateRoute path="/saved-words" component={Account} />
          <Route path="/" component={App} />
        </Switch>
      </SelectedRouter>
    </AppProvider>
  );
}

export default AppRouter;
