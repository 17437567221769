import { useContext, useState, useEffect } from "react";

const Features = ({ isGetStarted }) => {
  const [currentFeatureTab, setCurrentFeatureTab] = useState(
    isGetStarted ? 4 : 1
  );
  return (
    <section
      id="features"
      className="relative pt-[150px] md:pt-0 md:h-screen bg-customBlack md:snap-start"
    >
      <div className="relative md:h-screen max-w-[800px] flex items-center mx-auto">
        <div className="flex flex-col">
          <div class="tabs-container boxShadow">
            <div class="tabs-block">
              <div id="tabs-section" class="tabs">
                <ul class="tab-head">
                  <li>
                    <a
                      class="tab-link"
                      href="#tab-2"
                      className={
                        currentFeatureTab === 1 ? `tab-link active` : "tab-link"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentFeatureTab(1);
                      }}
                    >
                      <svg
                        fill="currentColor"
                        width="23px"
                        height="23px"
                        className="inline-block mx-auto"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2M4 12h4v2H4zm10 6H4v-2h10zm6 0h-4v-2h4zm0-4H10v-2h10z"></path>
                      </svg>
                      <span class="tab-label">Customize Subtitles</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab-1"
                      className={
                        currentFeatureTab === 2 ? `tab-link active` : "tab-link"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentFeatureTab(2);
                      }}
                    >
                      <svg
                        fill="currentColor"
                        width="20px"
                        height="20px"
                        viewBox="0 0 52 52"
                        className="inline-block mx-auto"
                      >
                        <path d="M39,18.67H35.42l-4.2,11.12A29,29,0,0,1,20.6,24.91a28.76,28.76,0,0,0,7.11-14.49h5.21a2,2,0,0,0,0-4H19.67V2a2,2,0,1,0-4,0V6.42H2.41a2,2,0,0,0,0,4H7.63a28.73,28.73,0,0,0,7.1,14.49A29.51,29.51,0,0,1,3.27,30a2,2,0,0,0,.43,4,1.61,1.61,0,0,0,.44-.05,32.56,32.56,0,0,0,13.53-6.25,32,32,0,0,0,12.13,5.9L22.83,52H28l2.7-7.76H43.64L46.37,52h5.22Zm-15.3-8.25a23.76,23.76,0,0,1-6,11.86,23.71,23.71,0,0,1-6-11.86Zm8.68,29.15,4.83-13.83L42,39.57Z" />
                      </svg>{" "}
                      <span class="tab-label">Translate Words</span>
                    </a>
                  </li>

                  <li>
                    <a
                      class="tab-link"
                      href="#tab-3"
                      className={
                        currentFeatureTab === 3 ? `tab-link active` : "tab-link"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentFeatureTab(3);
                      }}
                    >
                      <svg
                        height="18px"
                        width="18px"
                        viewBox="0 0 27.963 27.963"
                        className="inline-block mx-auto"
                      >
                        <path
                          fill="currentColor"
                          d="M13.98,0C6.259,0,0,6.26,0,13.982s6.259,13.981,13.98,13.981c7.725,0,13.983-6.26,13.983-13.981
C27.963,6.26,21.705,0,13.98,0z M21.102,16.059h-4.939v5.042h-4.299v-5.042H6.862V11.76h5.001v-4.9h4.299v4.9h4.939v4.299H21.102z"
                        />
                      </svg>
                      <span class="tab-label">Save Words</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab-4"
                      class={
                        currentFeatureTab === 4 ? `tab-link active` : "tab-link"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentFeatureTab(4);
                      }}
                    >
                      {isGetStarted ? (
                        <svg
                          fill="currentColor"
                          width="22px"
                          height="22px"
                          viewBox="0 0 554.2 554.199"
                          className="inline-block mx-auto"
                        >
                          <g>
                            <path
                              d="M538.5,386.199L356.5,70.8c-16.4-28.4-46.7-45.9-79.501-45.9c-32.8,0-63.1,17.5-79.5,45.9L12.3,391.6
		c-16.4,28.4-16.4,63.4,0,91.8C28.7,511.8,59,529.3,91.8,529.3H462.2c0.101,0,0.2,0,0.2,0c50.7,0,91.8-41.101,91.8-91.8
		C554.2,418.5,548.4,400.8,538.5,386.199z M316.3,416.899c0,21.7-16.7,38.3-39.2,38.3s-39.2-16.6-39.2-38.3V416
		c0-21.601,16.7-38.301,39.2-38.301S316.3,394.3,316.3,416V416.899z M317.2,158.7L297.8,328.1c-1.3,12.2-9.4,19.8-20.7,19.8
		s-19.4-7.7-20.7-19.8L237,158.6c-1.3-13.1,5.801-23,18-23H299.1C311.3,135.7,318.5,145.6,317.2,158.7z"
                            />
                          </g>
                        </svg>
                      ) : (
                        <svg
                          fill="currentColor"
                          height="25px"
                          width="25px"
                          className="inline-block mx-auto"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                        >
                          <path d="M17.41 6.59 15 5.5l2.41-1.09L18.5 2l1.09 2.41L22 5.5l-2.41 1.09L18.5 9zm3.87 6.13L20.5 11l-.78 1.72-1.72.78 1.72.78.78 1.72.78-1.72L23 13.5zm-5.04 1.65 1.94 1.47-2.5 4.33-2.24-.94c-.2.13-.42.26-.64.37l-.3 2.4h-5l-.3-2.41c-.22-.11-.43-.23-.64-.37l-2.24.94-2.5-4.33 1.94-1.47c-.01-.11-.01-.24-.01-.36s0-.25.01-.37l-1.94-1.47 2.5-4.33 2.24.94c.2-.13.42-.26.64-.37L7.5 6h5l.3 2.41c.22.11.43.23.64.37l2.24-.94 2.5 4.33-1.94 1.47c.01.12.01.24.01.37s0 .24-.01.36M13 14c0-1.66-1.34-3-3-3s-3 1.34-3 3 1.34 3 3 3 3-1.34 3-3"></path>
                        </svg>
                      )}
                      <span class="tab-label">
                        {isGetStarted ? "Important Warning" : "Other Features"}
                      </span>
                    </a>
                  </li>
                  {/* <li>
                <a href="#tab-5" class="tab-link">
                  {" "}
                  <span class="material-icons tab-icon">toll</span>{" "}
                  <span class="tab-label">Blush</span>
                </a>
              </li> */}
                </ul>

                <section
                  id="tab-2"
                  className={
                    currentFeatureTab === 1
                      ? `tab-body entry-content active active-content`
                      : "tab-body entry-content"
                  }
                >
                  <h2 className="mb-2 text-3xl text-center">
                    Customize Subtitles
                  </h2>
                  <p>
                    You can drag subtitles on the screen and place them in the
                    best possible location.
                  </p>
                  <p>
                    You can customize subtitles by changing the font size and
                    the background of the subtitles.
                  </p>
                  <p>
                    If a video lacks subtitles, you can add your own, and
                    they’ll display as native to the platform.
                  </p>
                </section>

                <section
                  id="tab-1"
                  className={
                    currentFeatureTab === 2
                      ? `tab-body entry-content active active-content`
                      : "tab-body entry-content"
                  }
                >
                  <h2 className="mb-2 text-3xl text-center">Translate Words</h2>
                  <p>
                    Choose your native language, and any subtitles will be
                    translated into it.
                  </p>
                  <p> You can translate a word by clicking on it.</p>
                  <p>
                    You can also translate an entire sentence by clicking on the
                    black background of the subtitles.
                  </p>
                </section>

                <section
                  id="tab-3"
                  className={
                    currentFeatureTab === 3
                      ? `tab-body entry-content active active-content`
                      : "tab-body entry-content"
                  }
                >
                  <h2 className="mb-2 text-3xl text-center">Save Words</h2>
                  <p>You can save the word that you just translated.</p>
                  <p>
                    This word will be added to the database, and later you can
                    use it to learn and review.
                  </p>
                  <p>
                    You can also export it to Excel and use other word learning
                    services, such as{" "}
                    <a
                      href="https://vocabularying.com"
                      className="text-yellow-500"
                      target="_blank"
                      rel="noreferrer"
                    >
                      vocabularying.com
                    </a>
                    .
                  </p>
                </section>

                <section
                  id="tab-4"
                  className={
                    currentFeatureTab === 4
                      ? `tab-body entry-content active active-content`
                      : "tab-body entry-content"
                  }
                >
                  <h2 className="mb-2 text-3xl text-center">
                    {isGetStarted ? "Important Warning" : "Other Features"}
                  </h2>
                  {isGetStarted ? (
                    <>
                      <p>
                        Sometimes this extension may not be initialized
                        properly. In that case, you only need to reload the page
                        until
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 128 128"
                          style={{
                            width: "24px",
                            display: "inline-block",
                            margin: "-2px 10px 0",
                          }}
                        >
                          <defs>
                            <style>
                              {`
	.st0{display:none;}
	.st1{display:inline;fill-rule:evenodd;clip-rule:evenodd;}
	.st2{fill:#FFFFFF;fill-rule:evenodd;clip-rule:evenodd;}
	.st3{fill:#000000;}
	.st4{fill:none;stroke:#FF0000;stroke-width:6;stroke-miterlimit:10;}
                        `}
                            </style>
                          </defs>
                          <g id="Layer_1" class="st0">
                            <path
                              class="st1"
                              d="M11.6,15C5.2,15,0,20.2,0,26.6v74.7c0,6.4,5.2,11.6,11.6,11.6h104.7c6.4,0,11.6-5.2,11.6-11.6V26.6
		c0-6.4-5.2-11.6-11.6-11.6H11.6z M92,89c-3.3,0-6,2.7-6,6s2.7,6,6,6h18c3.3,0,6-2.7,6-6s-2.7-6-6-6H92z M12,95c0-3.3,2.7-6,6-6h54
		c3.3,0,6,2.7,6,6s-2.7,6-6,6H18C14.7,101,12,98.3,12,95z M32.9,33.7V73h25.7v-6H40V56.3h17.1v-6H40V39.7h18.4v-6H32.9z M86.9,40.6
		c1.3,0.9,2.1,2.2,2.3,3.9H96c0-2.2-0.7-4.2-1.9-5.9c-1.2-1.7-2.9-3-5-4c-2.1-1-4.5-1.5-7.3-1.5c-2.7,0-5.2,0.5-7.4,1.5
		c-2.2,1-3.9,2.3-5.2,4c-1.3,1.7-1.9,3.8-1.9,6.1c0,2.8,0.9,5.1,2.8,6.8c1.9,1.7,4.4,2.9,7.7,3.8l4.5,1.2c1.4,0.4,2.6,0.8,3.7,1.2
		c1.1,0.4,1.9,1,2.5,1.7c0.6,0.7,0.9,1.5,0.9,2.5c0,1.1-0.4,2.1-1,3c-0.7,0.8-1.6,1.5-2.8,2c-1.2,0.5-2.5,0.7-4.1,0.7
		c-1.5,0-2.8-0.2-4-0.7c-1.2-0.4-2.2-1.1-2.9-2s-1.2-2-1.3-3.4h-7c0.1,2.6,0.8,4.8,2,6.6c1.3,1.8,3,3.2,5.3,4.1
		c2.2,0.9,4.9,1.4,8,1.4c3.1,0,5.8-0.5,8-1.5c2.2-1,3.9-2.3,5.1-4.1c1.2-1.8,1.8-3.8,1.8-6.1c0-1.7-0.3-3.2-1-4.4
		c-0.6-1.3-1.5-2.3-2.6-3.2c-1.1-0.9-2.3-1.6-3.7-2.2c-1.4-0.6-2.8-1.1-4.3-1.4l-3.7-0.9c-0.8-0.2-1.6-0.4-2.4-0.7
		c-0.8-0.3-1.5-0.6-2.2-1c-0.7-0.4-1.2-0.9-1.6-1.5c-0.4-0.6-0.6-1.3-0.6-2.1c0-1,0.3-1.9,0.9-2.6c0.6-0.8,1.4-1.4,2.4-1.8
		c1.1-0.4,2.3-0.7,3.8-0.7C83.9,39.2,85.6,39.7,86.9,40.6z"
                            ></path>
                          </g>
                          <g id="Layer_2">
                            <path
                              class="st2"
                              d="M116,113H12c-6.6,0-12-5.4-12-12V27c0-6.6,5.4-12,12-12h104c6.6,0,12,5.4,12,12v74
		C128,107.6,122.6,113,116,113z"
                            ></path>
                          </g>
                          <g id="Layer_3">
                            <g>
                              <path
                                class="st3"
                                d="M19,91.5V57.2h9.1v34.2H19z"
                              ></path>
                              <path
                                class="st3"
                                d="M68.5,91.5h-9.1V74c0-3.7-0.2-6.1-0.6-7.2c-0.4-1.1-1-1.9-1.9-2.5c-0.9-0.6-1.9-0.9-3.1-0.9
			c-1.6,0-3,0.4-4.2,1.3c-1.2,0.9-2.1,2-2.6,3.4c-0.5,1.4-0.7,4-0.7,7.9v15.5h-9.1V57.2h8.4v5c3-3.9,6.7-5.8,11.3-5.8
			c2,0,3.8,0.4,5.5,1.1c1.7,0.7,2.9,1.6,3.8,2.8c0.8,1.1,1.4,2.4,1.8,3.8s0.5,3.4,0.5,6.1V91.5z"
                              ></path>
                              <path
                                class="st3"
                                d="M76.8,93.7L87.2,95c0.2,1.2,0.6,2,1.2,2.5c0.9,0.6,2.2,1,4.1,1c2.4,0,4.1-0.4,5.3-1.1
			c0.8-0.5,1.4-1.2,1.8-2.3c0.3-0.8,0.4-2.1,0.4-4.2v-5c-2.7,3.7-6.1,5.5-10.2,5.5c-4.6,0-8.2-1.9-10.9-5.8
			c-2.1-3.1-3.2-6.9-3.2-11.5c0-5.7,1.4-10.1,4.1-13.1s6.2-4.5,10.3-4.5c4.2,0,7.7,1.9,10.5,5.6v-4.8h8.5V88c0,4-0.3,7.1-1,9.1
			s-1.6,3.6-2.8,4.7s-2.8,2-4.8,2.7s-4.5,1-7.6,1c-5.8,0-9.9-1-12.3-3s-3.6-4.5-3.6-7.6C76.8,94.5,76.8,94.2,76.8,93.7z M84.9,73.6
			c0,3.6,0.7,6.3,2.1,8c1.4,1.7,3.1,2.5,5.2,2.5c2.2,0,4.1-0.9,5.6-2.6c1.5-1.7,2.3-4.3,2.3-7.7c0-3.5-0.7-6.2-2.2-7.9
			c-1.5-1.7-3.3-2.6-5.5-2.6c-2.2,0-4,0.8-5.4,2.5C85.6,67.6,84.9,70.2,84.9,73.6z"
                              ></path>
                            </g>
                            <line
                              class="st4"
                              x1="19.5"
                              y1="51.1"
                              x2="65.5"
                              y2="26.9"
                            ></line>
                            <line
                              class="st4"
                              x1="108.5"
                              y1="51.1"
                              x2="62.5"
                              y2="26.9"
                            ></line>
                          </g>
                        </svg>
                        icon appears in the player.
                      </p>
                      <p>
                        <img
                          src="/static/warning.png"
                          alt="warning"
                          className="w-[330px] mx-auto"
                        />
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        You can translate a word by clicking on the plus icon.
                      </p>
                      <p>
                        Right now our extension supports subtitle translation on
                        Netflix, Youtube Coursera and Amazon Video Prime.
                      </p>
                      <p>
                        We are constantly working on improving user expeirience
                        to bring the best service for you.
                      </p>
                    </>
                  )}
                </section>

                <section
                  id="tab-5"
                  className={
                    currentFeatureTab === 5
                      ? `tab-body entry-content active active-content`
                      : "tab-body entry-content"
                  }
                >
                  <h2>Blush</h2>
                  <p>
                    Putting on blush can have a huge effect on your overall
                    look, and I personally never leave it out of my makeup
                    routine. Blush is especially necessary if you’re wearing a
                    foundation with more opaque coverage, which can sometimes
                    leave your complexion looking a little bit flat.
                  </p>
                  <p>
                    Blush comes in powder, gel, and cream formulations, with
                    powder being the most popular. Recently, though, cream and
                    gel blush have become very popular as well.
                  </p>
                </section>
              </div>
            </div>
          </div>
          <div className="relative flex flex-row justify-between px-2 transition duration-500 mt-14 opacity-40 hover:opacity-100">
            <div className="absolute text-white -translate-x-1/2 -top-6 left-1/2">
              Supported Platforms
            </div>
            <div class="w-[17%] px-[3%] flex justify-center">
              <img src="/static/logo/prime.svg" class="w-full object-contain" />
            </div>

            <div class="w-[17%] flex justify-center">
              <img
                src="/static/logo/youtube.svg"
                class="w-full object-contain"
              />
            </div>

            <div class="w-[17%] flex justify-center">
              <img
                src="/static/logo/netflix.svg"
                class="w-full object-contain"
              />
            </div>
            <div class="w-[17%] flex justify-center">
              <img
                src="/static/logo/coursera.svg"
                class="w-full object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
